import { Component, Vue } from 'vue-property-decorator'
import Layout from '@/layouts/basic/basic.vue';
import {} from '@/components/base';
  
@Component({
  components: {
    Layout
  },
  beforeRouteEnter(to: any, from: any, next: any) {
    // console.log(from);
    // porta 8788 teste local pages do cloudflare
    if(window.location.hostname != 'localhost' || window.location.port == '8788'){
      window.location.replace('/static/404');
    }
    next();
  }
})
export default class NotFound extends Vue {
}
  